<template>
  <div class="question_container">
    <div class="question_answers">
      <create-avatar-questions></create-avatar-questions>
    </div>
    <div class="question_info">
      <question-info :description="questionDescript"></question-info>
    </div>
  </div>
  <navigation></navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import CreateAvatarQuestions from "@/components/questions/CreateAvatarQuestions";
import QuestionInfo from "@/components/QuestionInfo";

export default {
  name: "CreateAvatar",
  components: {QuestionInfo, CreateAvatarQuestions, Navigation},
  setup(){
    const questionDescript = 'Start med at vælge et navn for at menneskeliggøre din Buyer Persona. Både navnet og den avatar, du vælger, hjælper dig med at tænke på denne persona som en rigtig person. \n' +
        '\n' +
        'Tip: Overvej at navngive din Persona med deres branche for at gøre det lettere at huske, som f.eks. Marketing Michael eller Finans Helle\n'

    return{
      questionDescript
    }
  }
}
</script>
<style scoped>
</style>